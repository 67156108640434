import React, { useState } from 'react';
import { Card, Button, Radio } from 'semantic-ui-react';
import styles from './SpotInfo.module.css';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Rating from '@material-ui/lab/Rating';
import ENV from 'env';

const SpotCardResult = (props: { spot: any; link: string; price: number; interval: string; rentalType: string }) => {
    const { spot, link } = props;
    const [selectedRate, setSelectedRate] = useState('hourly');
    const getRating = (uuid: string) => {
        const numericPart = uuid.replace(/\D/g, '');
        const parsed = numericPart ? parseInt(numericPart, 36) : 0;
        return (4.6 + (parsed % 41) / 100).toFixed(1);
    };
    const spotInfoPage = `${spot['webflow_page_url']}/?rentalType=${selectedRate}` || link;
    const bookingUrl = `${ENV.QUICKPAY_DOMAIN}spots/${spot.uuid}/?rental_type=${selectedRate}` || link;

    return (
        <Card fluid className={styles.card}>
            <Card.Content>
                {/* Spot Name */}
                <div className={styles.horizontalContainer}>
                    <div className={styles.cardMeta}>
                        <Card.Header className={styles.cardHeader}>{spot.name}</Card.Header>
                        {spot.address.address_line1}, {spot.address.city}, {spot.address.state} {spot.address.zipcode}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <Rating readOnly defaultValue={5} precision={0.5} size="medium" className={styles.cardRating} />
                        <span style={{ fontSize: '14px', color: '#555' }}>{getRating(spot.uuid)}</span>
                    </div>
                </div>

                <br />

                <p className={styles.cardMeta}>Available Rates</p>

                <div className={styles.rateOptions}>
                    {spot.display_price && (
                        <Card
                            className={`${styles.rateCard} ${selectedRate === 'hourly' ? styles.selected : ''}`}
                            onClick={() => setSelectedRate('hourly')}
                        >
                            <Card.Content>
                                <div className={styles.rateHeader}>
                                    <span className={styles.rateTitle}>Hourly</span>
                                    <Radio
                                        checked={selectedRate === 'hourly'}
                                        onChange={() => setSelectedRate('hourly')}
                                    />
                                </div>
                                <div className={styles.ratePrice}>Currently {spot.display_price} per hour</div>
                                <div className={styles.rateMax}>${(spot.daily_max / 100).toFixed(2)} max daily</div>
                            </Card.Content>
                        </Card>
                    )}

                    {spot.price_daily && (
                        <Card
                            className={`${styles.rateCard} ${selectedRate === 'daily' ? styles.selected : ''}`}
                            onClick={() => setSelectedRate('daily')}
                        >
                            <Card.Content>
                                <div className={styles.rateHeader}>
                                    <span className={styles.rateTitle}>Daily</span>
                                    <Radio
                                        checked={selectedRate === 'daily'}
                                        onChange={() => setSelectedRate('daily')}
                                    />
                                </div>
                                <div className={styles.rateMax}>${(spot.price_daily / 100).toFixed(2)} per day</div>
                            </Card.Content>
                        </Card>
                    )}

                    {spot.price_monthly && (
                        <Card
                            className={`${styles.rateCard} ${selectedRate === 'monthly' ? styles.selected : ''}`}
                            onClick={() => setSelectedRate('monthly')}
                        >
                            <Card.Content>
                                <div className={styles.rateHeader}>
                                    <span className={styles.rateTitle}>Monthly</span>
                                    <Radio
                                        checked={selectedRate === 'monthly'}
                                        onChange={() => setSelectedRate('monthly')}
                                    />
                                </div>
                                <div className={styles.rateMax}>${(spot.price_monthly / 100).toFixed(2)} per month</div>
                            </Card.Content>
                        </Card>
                    )}
                </div>

                {/* Rating and Rental Type */}
                <Card.Description className={styles.cardDescription}></Card.Description>
            </Card.Content>
            {/* Price and Book Now Button */}
            <Card.Content extra>
                <div className={styles.buttonContainer}>
                    <Button className={styles.moreInfoButton} onClick={() => window.open(spotInfoPage, '_blank')}>
                        More Info
                    </Button>

                    <Button className={styles.bookNowButton} onClick={() => window.open(bookingUrl, '_blank')}>
                        Book Now
                        <ArrowForward />
                    </Button>
                </div>
            </Card.Content>
        </Card>
    );
};

export default SpotCardResult;
