import React from 'react';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Rating from '@material-ui/lab/Rating';
import styles from './Reviews.module.css';

const Reviews = ({ reviews }: any) => {
    return (
        <Grid container justify={'center'} alignContent={'center'} spacing={5} style={{ margin: '30px 0' }}>
            {reviews.map((review: any, index: number) => {
                return (
                    <Grid item key={`Review ${index}`}>
                        <div className={styles.container}>
                            <div className={styles.header}>
                                <Avatar alt={review.user_name} className={styles.avatar}>
                                    {review.user_name[0]}
                                </Avatar>

                                <h4 style={{ margin: 'auto' }}>{review.user_name}</h4>
                                <Rating
                                    readOnly
                                    defaultValue={5}
                                    precision={0.5}
                                    size="small"
                                    className={styles.rating}
                                />
                            </div>

                            <div className={styles.content}>{review.content}</div>
                        </div>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default Reviews;
