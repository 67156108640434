import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSpotsAction } from 'actions/actions';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import Footer from 'components/Footer/Footer';
import Results from 'components/Spots/Results';
import SpotMap from 'components/Spots/SpotMap';
import { Loader, Card, Grid } from 'semantic-ui-react';
import styles from 'components/Spots/SpotInfo.module.css';

const SpotsModuleContainer = (props: any) => {
    const { getSpots, spots, loading } = props;
    const params = queryString.parse(props.location.search);
    const [mapCenter, setMapCenter] = useState([0, 0]);
    const [zoom, setZoom] = useState([10]);
    const [showPopup, setShowPopup] = useState(false);
    const [markerId, setMarkerId] = useState(0);
    const rentalType = params.rentalType ? params.rentalType : 'hourly';
    const spotLoading = !spots.length;

    const getSpotsNearDraggedArea = (map: any) => {
        const draggedAreaCoords = map.getCenter();
        const zoom = map.getZoom();
        setZoom([zoom]);
        const { lat, lng } = draggedAreaCoords;
        setMapCenter([lat, lng]);
        getSpots([lat, lng], rentalType);
    };

    useEffect(() => {
        document.body.style.overflow = 'hidden'; // Disable scrolling
        return () => {
            document.body.style.overflow = ''; // Re-enable scrolling when component unmounts
        };
    }, []);

    useEffect(() => {
        if (params.lat && params.lng) {
            setMapCenter([+params.lat, +params.lng]);
            getSpots([+params.lat, +params.lng], rentalType);
        } else {
            // fallback to Tempe
            setMapCenter([33.512258, -112.072086]);
            getSpots([33.512258, -112.072086], rentalType);
        }
    }, [getSpots, rentalType, params.lat, params.lng]);

    return (
        <div className={styles.whiteBackground}>
            {!spotLoading ? (
                <>
                    <Card fluid>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <div className={styles.sidebar}>
                                        <Results spots={spots} rentalType={rentalType} />
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <SpotMap
                                        spots={spots}
                                        getSpotsNearDraggedArea={getSpotsNearDraggedArea}
                                        zoom={zoom}
                                        mapCenter={mapCenter}
                                        loading={loading}
                                        setMapCenter={setMapCenter}
                                        showPopup={showPopup}
                                        setShowPopup={setShowPopup}
                                        markerId={markerId}
                                        setMarkerId={setMarkerId}
                                        rentalType={rentalType}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card>
                    <Footer />
                </>
            ) : (
                <Loader active content="Loading spots" />
            )}
        </div>
    );
};

function mapStateToProps(state: any) {
    return {
        errorMessage: state.spots.errorMessage,
        loading: state.spots.loading,
        spots: state.spots.spots,
    };
}

const mapDispatchToProps = {
    getSpots: getSpotsAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpotsModuleContainer));
